import React from "react";
import {FaApple, FaAndroid  } from 'react-icons/fa';
import {isMobile} from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import {
  GooglePlayButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";


const openLink = () => {
  window.open('https://apps.apple.com/us/app/social-city/id6479172523?platform=iphone', '_blank');
}


export const Header = (props) => {
  const navigate = useNavigate();
  const openLinka = () => {
    navigate("/android");
  }

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 intro-text" style={{width: "100%"}}>
                <div style={{ display: "flex", justifyContent: "center", borderRadius: 50, width: "100%"}}>
                <h1 style={{ borderRadius: 50, width: "100%"}}>
                  {props.data ? props.data.title : "Loading"}
      
                </h1></div>
                {/* {!isMobile &&
                  <div style={{ display: "flex", width: "100%", justifyContent: "center", width: "100%"}}>
               
                  <p style={{ display: "flex", background: "rgba(0, 0, 0, 0.5)", borderRadius: 15, fontWeight: 380, width: "60vw"}}>{props.data ? props.data.paragraph : "Loading"}</p>
                 </div>} */}

                 <div style={{ display: "flex", direction: "ltr", justifyContent: "center", alignItems: "center",  marginBottom: "0.5%"}}>
                  <div style={{backgroundColor: "white", borderRadius: 15, color: "black", height: "100%", minHeight: "100%"}}>
                 <AppStoreButton
                  url={'https://apps.apple.com/us/app/social-city/id6479172523?platform=iphone'}
                  theme={"light"}
              
                  width={isMobile? 200: 190}
                  height={isMobile? 65: 50}
                /></div>
              </div>
            


              <div style={{ display: "flex", direction: "ltr", justifyContent: "center", alignItems: "center"}}>       <div style={{backgroundColor: "white", borderRadius: 15, color: "black"}} onClick={openLinka}>
                  <GooglePlayButton

                    theme={"light"}
                 
                    width={isMobile? 200: 190}
                    height={isMobile? 65: 50}
                  />
                </div>  </div>
             
                </div>
   
                </div>
                </div>
                </div>
                </div>
   </header>

  );
};
