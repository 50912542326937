import React, { useState, useEffect } from "react";

import SmoothScroll from "smooth-scroll";

import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Privacy = () => {
  // const [landingPageData, setLandingPageData] = useState({});
  // useEffect(() => {
  //   setLandingPageData(JsonData);
  // }, []);

  return (
  <div style={{ fontFamily: 'Arial', padding: '1em' }}>
  <h1 style={{ textAlign: 'center', color: '#333' }}>Terms and Privacy Policy</h1>
      <p> {"\n"}</p>

        <p style={{ fontWeight: "bold" }}>Terms and Conditions</p>
        <h3># Terms of Service</h3>
        <p>
          ## 1. Acceptance of Terms By accessing and using this social network
          (the "Service"), you accept and agree to be bound by the terms and
          provision of this agreement.
        </p>
        <p>
          ## 2. User Conduct You are responsible for the content you post. You
          will not post content that is illegal, harmful, threatening, abusive,
          or otherwise objectionable. We reserve the right to remove posts 
          we deem harmful to the platform.
        </p>
        <p>
          ## 3. Privacy Your privacy is important to us. Please review our
          Privacy Policy, which explains how we handle your personal data. If
          you are a resident of California, you may have additional rights under
          the California Consumer Privacy Act (CCPA).
        </p>
        <p>
          ## 4. Intellectual Property You retain your rights to any content you
          submit, post or display on the Service. By submitting, posting or
          displaying content, you grant us a worldwide, non-exclusive,
          royalty-free license to use, copy, reproduce, process, adapt, modify,
          publish, transmit, display and distribute such content.
        </p>
        <p>
          ## 5. DMCA We respect the intellectual property rights of others and
          expect users to do the same. In accordance with the Digital Millennium
          Copyright Act (DMCA), we will respond promptly to notices of alleged
          infringement that are reported to our designated copyright agent.
        </p>
        <p>
          ## 6. Feedback and Suggestions You may choose to or we may invite you
          to submit comments, suggestions, or ideas about the Service, including
          how to improve the Service ("Ideas"). By submitting any Ideas, you
          agree that your submissions are voluntary, gratuitous, unsolicited,
          and without restriction and will not place us under any fiduciary or
          other obligation.
        </p>
        <p>
          ## 7. Termination We may terminate or suspend access to our Service
          immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if you breach the Terms.
        </p>
        <p>
          ## 8. Changes to Terms We reserve the right, at our sole discretion,
          to modify or replace these Terms at any time.
        </p>
        <p>
          ## 9. Contact Us If you have any questions about these Terms, please
          contact us at [Your Contact Information].
        </p>
        <p>
          ## 10. Governing Law These Terms shall be governed and construed in
          accordance with the laws of California, United States, without regard
          to its conflict of law provisions.
        </p>
        <p> {""}</p>
        <h2 style={{ color: '#555', marginTop: '2em' }}>Privacy Policy</h2>
  <h3># Privacy </h3>
        <p>
          ## 1. Introduction: Welcome to Social City. This Privacy Policy
          outlines our data collection practices. It explains what data we collect, 
          how we use it, and your rights in relation to it.
        </p>
        <p>
          ## 2. Personal Data Collection: When you use Social City, we may collect
          certain information such as your account metadata, interactions with other 
          users and the platform, your location, and the content you create or upload.
        </p>
        <p>
          ## 3. Sharing of Personal Data: We do not share, sell, trade, or rent
          your data to third parties. The exception is when cooperating with law
          enforcement.
        </p>
        <p>
          ## 4. Use of Personal Data: We use your personal data to provide and
          improve our Service, personalize your experience, and provide
          functionality for the app.
        </p>
        <p>
          ## 5. Sign in with Google/Apple: When using sign in with google or apple 
          we only request your unique "sub" attribute. We store this attribute in a database.
          We associate your user account with that identifier. See Google federation identity provider 
          for more information on authentication systems.
    
        </p>
        <p>
          ## 6. Security: We are committed to ensuring that your information is
          secure. We have implemented suitable physical, electronic, and
          managerial procedures to safeguard the information we collect.
          Authorization is provided by multiple encryption techniques and
          integrations with Amazon Web Services. We have implemented encryption in transit 
          and at rest for majority of non-media data. 
        </p>
        <p>
          ## 7. Your Rights: You have the right to access, correct, or delete
          your data. If you are a resident of California, you may have
          additional rights under the California Consumer Privacy Act (CCPA).
        </p>
        <p>
          ## 8. Changes to This Privacy Policy: We may update our Privacy Policy
          from time to time. We will notify you of any changes by posting the
          new Privacy Policy on this page.
        </p>
        <p>
          ## 9. Contact Us If you have any questions about this Privacy Policy,
          please contact us at [Your Contact Information].
        </p>
        <p> {""}</p>
  </div>
   
  );
};

export default Privacy;
