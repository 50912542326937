import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Privacy from "./privacy";
import Signup from "./signup"

import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Main = () => {
  return (
    <div className="root">
      <Navigation />
      <Header data={JsonData.Header} />
      <About data={JsonData.About} />
      {/* <Services data={landingPageData.Services} /> */}
      <Gallery data={JsonData.Gallery} />
      <Contact data={JsonData.Contact} />
    </div>
  )
}
const App = () => {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/android" element={<Signup />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Router>
   
  );
};

export default App;
