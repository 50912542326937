import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  const ist = window.innerWidth < window.innerHeight
  return (
    <div id="portfolio">
      <div className="container" style={{alignSelf: "center", alignContent: "center", alignItems: "center"}}>
        <div className="section-title" >
          <h2>Gallery</h2>
          <p>Social City does have a hidden dating aspect but with a friends first approach. 
            After you've been friends with someone for a while, you'll be able to send them a secret like. If two people like each other they will be notified. Connect authentically without the up front awkwardness & pressure of traditional swipe/match apps</p>
 
        </div>
        <div className="row">
        <div className="col-xs-12 col-md-4" style={ist ? {marginRight: "1%", marginLeft: "-1%"}: undefined}>
            <img src="img/gal6.png" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-4" style={ist ? {marginRight: "11%", marginLeft: "-1%"}: undefined}>
            <img src="img/gal1.png" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-4" style={ist ? {marginRight: "1%", marginLeft: "-1%"}: undefined}>
            <img src="img/gal5.png" className="img-responsive" alt="" />
          </div>
        </div>
        <div className="row">
        <div className="col-xs-12 col-md-4" style={ist ? {marginRight: "1%", marginLeft: "-1%"}: undefined}>
            <img src="img/gal3.png" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-4" style={ist ? {marginRight: "1%", marginLeft: "-1%"}: undefined}>
            <img src="img/gal4.png" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-4" style={ist ? {marginRight: "1%", marginLeft: "-1%"}: undefined}>
            <img src="img/gal2.png" className="img-responsive" alt="" />
          </div>
        </div>
          {/* <div className="row">
            <div className="col-xs-12 col-md-4">
                <img src="img/gal3" className="img-responsive" alt="" />
              </div>
              <div className="col-xs-12 col-md-4">
                <img src="img/gal4" className="img-responsive" alt="" />
              </div>
              <div className="col-xs-12 col-md-4">
                <img src="img/gal5" className="img-responsive" alt="" />
              </div>
            </div> */}
          </div>
        </div>

  );
};
