import React, { useState, useEffect } from "react";

import emailjs from '@emailjs/browser';

import "./App.css";

import { useNavigate } from "react-router-dom";
// there is a cap limit on this please don't bomb it 
const SERVICE_ID = 'service_g9qxmao'
const TID = 'template_w36u9z6'
const PUBLIC = 'xWp7wxU6Oyjl9p9O9'
const Signup = () => {
  const navigate = useNavigate();
  const form = React.useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(" form ")

    const email = form.current.user_email.value
    const message = form.current.message.value
    console.log("email ", email, message)
    emailjs
      .send(SERVICE_ID, TID, {
        from_name: email,
        message: message
      }, {
        publicKey: PUBLIC,
      })
      .then(
        () => {
          console.log('SUCCESS!');
          alert("Thank you for signing up!")
          navigate("/");

        },
        (error) => {
          console.log('FAILED...', error);
        },
      );
  };
  return (
    <div className="root" >
      <div style ={{marginTop: '50px'}}>
      <p style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', margin: '0 auto' }}>The android version is currently in a beta, to sign up for the beta, enter your email and you'll receive an invitation link within 48 hours.</p>
      < br />
      <form ref={form} onSubmit={sendEmail} style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', margin: '0 auto' }}>
        {/* <label style={{ marginBottom: '10px' }}>Name</label>
        <input type="text" name="user_name" style={{ padding: '10px', marginBottom: '20px' }} /> */}
        <label style={{ marginBottom: '10px' }}>Email</label>
        <input type="email" name="user_email" style={{ padding: '10px', marginBottom: '20px' }} />
        <label style={{ marginBottom: '10px' }}>Optional message</label>
        <textarea name="message" style={{ padding: '10px', marginBottom: '20px' }} />
        <input type="submit" value="Send" style={{ padding: '10px', background: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }} />
      </form>
      </div>
    </div>
  );
  
  

  // return (
  //   <div className="root">
  //     <p> send email</p>
  //   <form ref={form} onSubmit={sendEmail}>
  //     <label>Name</label>
  //     <input type="text" name="user_name" />
  //     <label>Email</label>
  //     <input type="email" name="user_email" />
  //     <label>Message</label>
  //     <textarea name="message" />
  //     <input type="submit" value="Send" />
  //   </form>
  //   </div>
  // );
}

export default Signup