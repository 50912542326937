import React from "react";
import {isMobile} from 'react-device-detect';
export const About = (props) => {
  const ist = isMobile
  return (
    <div id="about" >
      <div className="container">
        <div className="row">

          <div className="col-md-12">
            <div className="about-text">
              <h2>About </h2>
              <div style={{fontSize: 16}}>{props.data ? props.data.paragraph : "loading..."}</div>
              <br />
              <div style={{fontSize: 16}}>{props.data ? props.data.paratwo : ""}</div>
            </div>
          </div>
        </div>
        <div className="row" style = {ist? {width: "100%", marginLeft: "2%"} : undefined}>
        <div className={!isMobile && "col-xs-12 col-md-4" } style={ist ? {marginRight: "1%", marginLeft: "-5%"}: undefined}>
            <img src="img/aboutone.png" className="img-responsive" alt="" />
          </div>
          <div  className={!isMobile && "col-xs-12 col-md-4" }style={ist ? {marginRight: "1%", marginLeft: "-5%"}: undefined}>
            <img src="img/abouttwo.png" className="img-responsive" alt="" />
          </div>
          <div  className={!isMobile && "col-xs-12 col-md-4" }style={ist ? {marginRight: "1%", marginLeft: "-5%"}: undefined}>
            <img src="img/aboutthree.png" className="img-responsive" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
